<template>
  <!-- Sidebar starts -->
  <div class="flex flex-no-wrap h-screen bg-lightGrey pt-16 overflow-hidden">
    <div class="block top-16" :class="{ 'w-16': moved }" id="mobile-nav">
      <div
        class="bg-teal shadow overflow-scroll overflow-x-hidden transition-width transition-slowest ease"
        id="forHeight"
        :class="[moved ? 'w-16' : 'w-64']"
      >
        <div
          id="openSideBar"
          class="h-10 w-10 absolute flex items-center justify-center cursor-pointer"
          :class="[moved ? 'left-5' : 'left-52']"
          @click="sidebarHandler(true)"
        >
          <span v-if="!moved" class="material-icons text-white"> close </span>
          <span
            v-else
            class="text-white text-2xl material-icons transform rotate-180"
            >menu_open
          </span>
        </div>
        <div class="pt-14 text-white" :class="[moved ? 'px-2' : 'px-8']">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="pt-4"
          >
            <div v-if="!moved">
              <router-link :to="{ name: category.link }">
                <span
                  @click="category.open = !category.open"
                  class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>

                  <div class="flex items-end">
                    <span class="text-sm" :class="[moved ? 'hidden' : '']">{{
                      category.name
                    }}</span>
                  </div>
                </span>
              </router-link>
            </div>
            <div v-else>
              <span
                @click="category.open = !category.open"
                class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
              >
                <router-link
                  v-if="!category.query"
                  :to="{ name: category.link }"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: category.link,
                    query: { activeTab: category.query },
                  }"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidebar ends -->
    <!-- Content Start -->
    <central-card>
      <slot></slot>
    </central-card>
    <!-- Content Ends -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      moved: false,
      categories: {
        humanResourcesGroup: {
          name: "Vacancies & Candidates",
          icon: "person_add_alt",
          open: false,
          link: "re-vacancies-candidates",
          items: [{ name: "", link: "" }],
        },
        accountingNFinanceGroup: {
          name: "Interviews & Offers",
          icon: "interpreter_mode",
          open: false,
          link: "re-interviews-offers",
          items: [{ name: "", link: "" }],
        },
        complianceGroup: {
          name: "Locum Dashboard",
          icon: "assignment_turned_in",
          open: false,
          link: "re-locums",
          items: [{ name: "", link: "" }],
        },
        telephoneITGroup: {
          name: "All New Hire Questionnaires",
          icon: "medical_services",
          open: false,
          link: "re-vnew-hire-policies",
          items: [{ name: "", link: "" }],
        },
        employeeHandBookGroup: {
          name: "All Practice Policies",
          icon: "library_books",
          open: false,
          link: "re-employee-hand-books",
          items: [{ name: "", link: "" }],
        },
      },
    };
  },
  methods: {
    sidebarHandler() {
      this.moved = !this.moved;
      localStorage.setItem("moved", this.moved);
    },
  },
  mounted() {
    this.moved = localStorage.getItem("moved");
  },
  watch: {
    // $route() {
    //   if (!this.moved) {
    //     this.sidebarHandler();
    //   }
    // },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #ffb81c;
}
#forHeight {
  height: calc(100vh - 64px);
}
</style>
